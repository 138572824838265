import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-text-slider',
  template: `
    <div class="text-slider-container">
      <div [@slideAnimation]="state" class="text-slider-item">
        {{ currentText }}
      </div>
    </div>
  `,
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('200ms', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class TextSliderComponent {
  texts: string[] = ['Front End Developer', 'Back End Developer', 'Software Engineer'];
  currentText: string;
  state: string = 'in';

  constructor() {
    this.setCurrentText();
  }

  setCurrentText() {
    let index = 0;
    setInterval(() => {
      this.currentText = this.texts[index];
      this.state = this.state === 'in' ? 'out' : 'in';
      index = (index + 1) % this.texts.length;
    }, 1200);
  }
}
