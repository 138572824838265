<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-0">
                    <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="300" height="300" style="border-radius: 150px;" src="assets/images/me/me.jpeg" alt="Git User">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="margin-bottom: 12px;">
                    <ul class="skills-list" data-aos="fade-up">
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Programming Languages : <span class="underline">Java</span>, <span class="underline">Python</span>, <span class="underline">TypeScript</span>, <span class="underline">JavaScript</span>, <span class="underline">C</span>, <span class="underline">C++</span>, <span class="underline">SQL</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">UI/Web Technologies : <span class="underline">Angular</span>, <span class="underline">React</span>, <span class="underline">HTML</span>, <span class="underline">CSS</span>, <span class="underline">SCSS</span>, <span class="underline">Bootstrap</span>, <span class="underline">JQuery</span>, <span class="underline">JSON</span>, <span class="underline">XML</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Server Side : <span class="underline">Spring</span>, <span class="underline">Spring Boot</span>, <span class="underline">Hibernate</span>, <span class="underline">Spring Cloud</span>, <span class="underline">Flask</span>, <span class="underline">Node</span>, <span class="underline">REST API</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Cloud Technologies (Amazon Web Services) : <span class="underline">IAM</span>, <span class="underline">EC2</span>, <span class="underline">ELB</span>, <span class="underline">ASG</span>, <span class="underline">S3</span>, <span class="underline">Lambda</span>, <span class="underline">SNS</span>, <span class="underline">Beanstalk</span>, <span class="underline">RDS</span>, <span class="underline">DynamoDB</span>, <span class="underline">SQS</span>, <span class="underline">Route 53</span>, <span class="underline">API Gateway</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">J2EE technologies : <span class="underline">JSP</span>, <span class="underline">Servlet</span>, <span class="underline">JPA</span>, <span class="underline">JSTL</span>, <span class="underline">JDBC</span>, <span class="underline">EJB</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Message Service : <span class="underline">RabbitMQ</span>, <span class="underline">Apache Kafka</span>, <span class="underline">ActiveMQ</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Databases : <span class="underline">MySQL</span>, <span class="underline">Oracle</span>, <span class="underline">PostgreSQL</span>, <span class="underline">MongoDB</span>, <span class="underline">Cassandra</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Data Science : <span class="underline">Pandas</span>, <span class="underline">OpenCV</span>, <span class="underline">NumPy</span>, <span class="underline">Scikit-Learn</span>, <span class="underline">Scipy</span>, <span class="underline">Matplotlib</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Machine Learning : <span class="underline">PyTorch</span>, <span class="underline">TensorFlow</span>, <span class="underline">Keras</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Business Intelligence : <span class="underline">Tableau</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Version Control and CI/CD : <span class="underline">Git</span>, <span class="underline">Docker</span>, <span class="underline">Jenkins</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Testing Tools : <span class="underline">JUnit</span>, <span class="underline">Mockito</span>, <span class="underline">Postman</span>, <span class="underline">Swagger</span></li>
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">Operating Systems : <span class="underline">Windows</span>, <span class="underline">Linux</span>, <span class="underline">Mac OS</span></li>
                    </ul>
                </div>
            </div>
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title" style="margin-top: 12px;margin-bottom: 10px;">
                    {{"Certification.title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12">
                    <ul class="skills-list" data-aos="fade-up">
                        <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">AWS Certified Cloud Practitioner </span> (Credential ID MC1VXQ3K7EB1QMCH)</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
