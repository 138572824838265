import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageService } from "src/app/services/language/language.service"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Deep-Patel-Portfolio';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService
  ) {
  }
  ngOnInit(): void {

    this.languageService.initLanguage()
    //update the below code for title of page and decription according to you
    this.titleService.setTitle("Deep Patel | Full Stack Developer | AI Developer | Software Engineer");
    this.metaService.addTags([
      { name: 'keywords', content: 'Full Stack Developer , AI Developer , Software Engineer, software, developer' },
      { name: 'description', content: 'I have 2 years of experience as a Full Stack Developer and Software Developer. My expertise lies in writing and refactoring high-quality, maintainable, and scalable code using  Java,  Python,  TypeScript, and  JavaScript. I am skilled in enhancing UI/UX through the use of HTML, CSS, Angular, React, and Bootstrap. Additionally, I have also administrate several SQL and NoSQL Databases.' },
    ]);
    
    AOS.init();


  }
}
