import { Component, OnInit } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { take, concatMap, repeat, delay } from 'rxjs/operators';

@Component({
  selector: 'app-text-typing-hello-slider',
  templateUrl: './text-typing-slider.component.html',
  styleUrls: ['./text-typing-slider.component.scss']
})
export class TextTypingSliderComponent implements OnInit {
  texts: string[] = ['Full Stack Developer','Python Developer','Java Developer', 'Software Developer'];
  animatedText = '';
  currentIndex = 0;

  constructor() { }

  ngOnInit() {
    this.startTypingAnimation();
  }

  startTypingAnimation() {
    const text = this.texts[this.currentIndex];
    const typingSpeed = 70; // Speed in milliseconds between each character
    const deleteSpeed = 50; // Speed in milliseconds between each character deletion

    let isDeleting = false;
    let currentTextIndex = 0;
    let typingInterval: any;
    typingInterval = setInterval(() => {
      if (!isDeleting) {
        this.animatedText += text[currentTextIndex];
        currentTextIndex++;

        if (currentTextIndex === text.length) {
          isDeleting = true;
          clearInterval(typingInterval);
          setTimeout(() => {
            typingInterval = setInterval(() => {
              if (this.animatedText.length === 0) {
                isDeleting = false;
                currentTextIndex = 0;
                clearInterval(typingInterval);
                this.currentIndex = (this.currentIndex + 1) % this.texts.length;
                this.startTypingAnimation();
              } else {
                this.animatedText = this.animatedText.slice(0, -1);
              }
            }, deleteSpeed);
          }, 600); // Delay before starting the deletion animation
        }
      } else {
        this.animatedText = this.animatedText.slice(0, -1);

        if (this.animatedText.length === 0) {
          isDeleting = false;
          currentTextIndex = 0;
          clearInterval(typingInterval);
          this.currentIndex = (this.currentIndex + 1) % this.texts.length;
          this.startTypingAnimation();
        }
      }
    }, typingSpeed);
  }
}